import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { User, UsersQueryResponse } from './_models'
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL
const PRODUCT_URL = `${API_URL}/product`;
const ADMIN_URL= `${API_URL}/admin`;

axios.interceptors.response.use(config => {
  return config
},
error => {
  const status = error?.request?.status || 0;
  if (status === 403) {
     if (localStorage.getItem('kt-auth-react-v')) {
        toast.error('Token Expired');
        localStorage.clear()
        setTimeout(()=>{
          window.location.assign('/');
        },1500)
        return Promise.reject(error);
     } else {
        return Promise.reject(error)
     }
  }
  return Promise.reject(error)
})

const getProducts = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${PRODUCT_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getProductById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${PRODUCT_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createProduct = (user: User): Promise<User | undefined> => {
  return axios
    .post(PRODUCT_URL + `/add`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const duplicateProduct = (user: User): Promise<User | undefined> => {
  return axios
    .post(PRODUCT_URL + `/duplicate`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateProduct = (id: any , user: User): Promise<User | undefined> => {
  return axios
    .put(`${PRODUCT_URL}/${id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteProduct = (id: ID): Promise<any | undefined> => {
  return axios
  .delete(`${PRODUCT_URL}/${id}`)
  .then((response: any) => response.data)
}

const changeProductStatus = (id: ID, isActive: any): Promise<any | undefined> => {
  return axios
    .put(`${PRODUCT_URL}/${id}`, { isActive })
    .then((response: AxiosResponse<Response<User>>) => response.data).catch((ex:any)=>ex.response)
}

const changeProductIsForSale = (id: ID, isForSale: any): Promise<any | undefined> => {
  return axios
    .put(`${PRODUCT_URL}/${id}`, { isForSale })
    .then((response: AxiosResponse<Response<User>>) => response.data).catch((ex:any)=>ex.response)
}


const deleteSelectedProducts = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${PRODUCT_URL}/${id}`))
  return axios.all(requests).then(() => { })
}


const getUser = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${ADMIN_URL}${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data).catch((ex:any)=>{
      return ex;
    })
}
export { changeProductStatus, changeProductIsForSale, getProducts, getProductById, createProduct, updateProduct, deleteProduct, deleteSelectedProducts, getUser, duplicateProduct }
